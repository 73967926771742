@charset "UTF-8";

.navigation.variant1{}
.navigation.variant1 ul{}
.navigation.variant1 ul li{list-style-type:none;border-bottom:$block-navigation-underline;}
.navigation.variant1 ul li a{padding:$padding-small 0;display:inline-block;}
.navigation.variant1 ul li.active > a{color:$secondary-color;}
.navigation.variant1 ul li ul{margin:0;border-top:$block-navigation-underline;list-style-position:inside;}
.navigation.variant1 ul li ul li{list-style-type:disc;padding-left:$padding-normal;}
.navigation.variant1 ul li ul li.last{border:0;}
.navigation.variant1 ul li ul li a{padding:$padding-small 0 $padding-small $padding-small;font-size:$main-font-size * 0.85;}
.navigation.variant1 ul li ul li.active{color:$secondary-color;}
.navigation.variant1 ul li ul li.active > a{color:$secondary-color;}
