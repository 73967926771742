@charset "UTF-8";

.memberSearch.variant1 {
	.gm-style-iw ul li {font-size:13px;}
	.GoogleMapResult_Map {
		height: 400px;
		margin-bottom:$margin-large;
		@if not $memberSearchVariant1ShowMap {
			display:none!important;
		}
	}
	.GoogleMapResult_Grid{
		@if not $memberSearchVariant1ShowGrid {
			display:none!important;
		}
		.memberSearchMember{
			margin-bottom:$margin-normal;
			padding-bottom: $padding-normal;
			border-bottom:1px solid $border-color;
			h5{
				font-weight:bold;
			}
			h5.informalName{
				display:none;
			}
			h5.formalName{
				font-size:85%;
			}
		}
		.paging{
			text-align:center;
			.page,
			.activePage{
				display:inline-block;
				font-size:rem-calc(16);
				padding:3px 10px;
				margin:0 5px 0 0;
			}
			.page{
				color:$primary-color;
				&:hover{
					background:#f8f8f8;
				}
			}
			.activePage{
				background:$primary-color;
				color:#fff;
			}
		}
	}
}