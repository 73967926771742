@import "variables";
@import "./versions/2.0.9/base/scss/meetingSubscriptionFlowVariant1";
@import "./versions/2.0.9/base/scss/meetingVariant1";
@import "./versions/2.0.9/base/scss/datepicker";
// Theme - only 1 entry possible!!
@import "./versions/2.0.9/#themes/neptunus";

// inject:defaultWidgets:scss
@import "./versions/2.0.9/defaultWidgets/accordionVariant1/scss/_accordionVariant1.scss";
@import "./versions/2.0.9/defaultWidgets/addFunctionVariant1/scss/_addFunctionVariant1.scss";
@import "./versions/2.0.9/defaultWidgets/alertVariant1/scss/_alertVariant1.scss";
@import "./versions/2.0.9/defaultWidgets/bannerVariant1/scss/_bannerVariant1.scss";
@import "./versions/2.0.9/defaultWidgets/changeAttributesVariant1/scss/_changeAttributesVariant1.scss";
@import "./versions/2.0.9/defaultWidgets/changeFunctionVariant1/scss/_changeFunctionVariant1.scss";
@import "./versions/2.0.9/defaultWidgets/changePasswordVariant1/scss/_changePasswordVariant1.scss";
@import "./versions/2.0.9/defaultWidgets/changePersonalInformationVariant1/scss/_changePersonInformationVariant1.scss";
@import "./versions/2.0.9/defaultWidgets/commentsVariant1/scss/_commentsVariant1.scss";
@import "./versions/2.0.9/defaultWidgets/cookieVariant1/scss/_cookieVariant1.scss";
@import "./versions/2.0.9/defaultWidgets/documentsVariant1/scss/_documentsVariant1.scss";
@import "./versions/2.0.9/defaultWidgets/documentDetailVariant1/scss/_documentDetailVariant1.scss";
@import "./versions/2.0.9/defaultWidgets/footerVariant1/scss/_footerVariant1.scss";
@import "./versions/2.0.9/defaultWidgets/formVariant1/scss/_formVariant1.scss";
@import "./versions/2.0.9/defaultWidgets/formV3Variant1/scss/_formV3Variant1.scss";
@import "./versions/2.0.9/defaultWidgets/galleryVariant1/scss/_galleryVariant1.scss";
@import "./versions/2.0.9/defaultWidgets/loginVariant1/scss/_loginVariant1.scss";
@import "./versions/2.0.9/defaultWidgets/meetingsVariant1/scss/_meetingsVariant1.scss";
@import "./versions/2.0.9/defaultWidgets/memberSearchVariant1/scss/_memberSearchVariant1.scss";
@import "./versions/2.0.9/defaultWidgets/navigationVariant1/scss/_navigationVariant1.scss";
@import "./versions/2.0.9/defaultWidgets/pagesSmallVariant1/scss/_pagesSmallVariant1.scss";
@import "./versions/2.0.9/defaultWidgets/pagesVariant1/scss/_pagesVariant1.scss";
@import "./versions/2.0.9/defaultWidgets/pagesVariant2/scss/_pagesVariant2.scss";
@import "./versions/2.0.9/defaultWidgets/pagesVariant3/scss/_pagesVariant3.scss";
@import "./versions/2.0.9/defaultWidgets/pagesVariant4/scss/_pagesVariant4.scss";
@import "./versions/2.0.9/defaultWidgets/pagesVariant5/scss/_pagesVariant5.scss";
@import "./versions/2.0.9/defaultWidgets/pagesVariant6/scss/_pagesVariant6.scss";
@import "./versions/2.0.9/defaultWidgets/pagesVariant7/scss/_pagesVariant7.scss";
@import "./versions/2.0.9/defaultWidgets/parallaxVariant1/scss/_parallaxVariant1.scss";
@import "./versions/2.0.9/defaultWidgets/pollVariant1/scss/_pollVariant1.scss";
@import "./versions/2.0.9/defaultWidgets/searchVariant1/scss/_searchVariant1.scss";
@import "./versions/2.0.9/defaultWidgets/sidebarVariant1/scss/_sidebarVariant1.scss";
@import "./versions/2.0.9/defaultWidgets/sliderVariant1/scss/_sliderVariant1.scss";
@import "./versions/2.0.9/defaultWidgets/sliderVariant2/scss/_sliderVariant2.scss";
@import "./versions/2.0.9/defaultWidgets/textVariant1/scss/_textVariant1.scss";
@import "./versions/2.0.9/defaultWidgets/videoVariant1/scss/_videoVariant1.scss";
// endinject:defaultWidgets

// inject:specialWidgets:scss
@import "./versions/2.0.9/specialWidgets/headerVariant3/scss/_headerVariant3.scss";
@import "./versions/2.0.9/specialWidgets/logoBarVariant1/scss/_logoBarVariant1.scss";
@import "./versions/2.0.9/specialWidgets/sliderVariant3/scss/_sliderVariant3.scss";
@import "./versions/2.0.9/specialWidgets/tabsVariant1/scss/_tabsVariant1.scss";
// endinject:specialWidgets

// inject:kollaWidgets:scss
@import "./versions/2.0.9/kollaWidgets/changeProfilePictureVariant1/scss/_changeProfilePictureVariant1.scss";
@import "./versions/2.0.9/kollaWidgets/kollaMyNetworksVariant1/scss/_kollaMyNetworksVariant1.scss";
@import "./versions/2.0.9/kollaWidgets/kollaRemainingNetworksVariant1/scss/_kollaRemainingNetworksVariant1.scss";
@import "./versions/2.0.9/kollaWidgets/kollaStreamVariant1/scss/_kollaStreamVariant1.scss";
@import "./versions/2.0.9/kollaWidgets/noticationSettingsVariant1/scss/_notificationSettingsVariant1.scss";
@import "./versions/2.0.9/kollaWidgets/showPersonProfilePictureVariant1/scss/_showProfilePersonVariant1.scss";
// endinject:kollaWidgets
