@charset "UTF-8";

.login.variant1 .loggedIn {display:none;}
.login.variant1 #lbl_username,
.login.variant1 #lbl_password {margin-bottom:$margin-small;}
.login.variant1 #login_rememberme_container {float:left;margin:0 10px 5px 0;}
.login.variant1 .login_error,
.login.variant1 #lbl_unable_to_send_password_msg {margin-bottom:1rem;color:$primary-color!important;}
.login.variant1 .login_error span {color:$primary-color!important;}
.login.variant1 #btn_login {clear:left; overflow:hidden;}
.login.variant1 #btn_login input,
.login.variant1 #input_container_retrievebutton input {@include button;}