@charset "UTF-8";

.tabBlock.variant1 .tab-title a {@include tabStyling;}
.tabBlock.variant1 .tab-title.active a {@include tabActiveStyling;}
.tabBlock.variant1 .tabs-content .content {@include tabContent;}

@if $vertical-tabs {
  .tabBlock.variant1 .tabs {float:left;width:40%;max-width:40%;margin:0 0 1.25rem 0;}
  .tabBlock.variant1 .tabs li {display:block;float:none;position:inherit;top:auto;}
  .tabBlock.variant1 .tabs-content {float:left;width:60%;max-width:60%;}
}
