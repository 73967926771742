@charset "UTF-8";

.meetings.variant1{
  .item{
    margin-bottom:$margin-normal;
    padding-bottom:$padding-normal;
    border-bottom:1px solid $border-color;
    p{
      overflow:hidden;
      @if $meetingsVariant1ShowImage {
        img{
          display:block;
          max-height:75px;
          margin:0 0 $margin-normal $margin-normal;
        }
      } @else {
        img{
          display:none;
        }
      }

    }
  }
}