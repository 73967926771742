.kollaStream.variant1{
  .events {}
  .item {overflow:hidden;padding-bottom:10px;margin-bottom:10px;border-bottom:1px solid #eee;}
  .item .info {text-align:center;}
  .item .info time {display:block;text-align:center;margin-bottom:10px;color:#777;}
  .item .profileContent {float:left;margin:14px 0 0 8px;text-align:center;}
  .item .profileContent .profileName {margin:0 0 0 5px;}
  .item .profilePicture {display:block;width:100%;margin:0 auto;text-align:center;margin-top:10px;}
  .item .avatar {border-radius:50%;border:3px solid #eee;transition: border-color ease-in 0.3s;}
  .item .avatar:hover {border-color:#CCC;transition: border-color ease-in 0.3s;}
  .item .content {background:#f1f1f1;display:block;clear:left;text-align:center;white-space:nowrap;margin:15px 0 0 0;padding:15px;position:relative;}
  .item .content > p:before {background:#f1f1f1;content:" ";height:20px;left:40px;position:absolute;top:-8px;transform:rotate(45deg);width:20px;}
  .item .content > p {overflow:hidden;text-overflow:ellipsis;margin:0;padding:0;}

  /* Border left right from avatar */
  .item {border-bottom:0;}
  .item .profilePicture {border-top:3px solid #eee;width:100%;margin-top:32px;}
  .item .profilePicture img {margin-top:-25px;box-shadow:0 0 0 10px #FFF;}

  /* Hover effect */
  /*.item {cursor:pointer;transition:transform ease-in 0.2s;}
  .item:hover .profilePicture img {box-shadow:0 0 0 10px #f7f7f7;}
  .item:hover {padding:10px;background:#f7f7f7;transition:transform ease-in 0.3s;transform:scale(1.1);}*/

}