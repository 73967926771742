@charset "UTF-8";

.changeAttributes.variant1 .attribute_container{clear:left;overflow:hidden;}
.changeAttributes.variant1 .modifypersonalattributeinfo_input_container_checkbox {float:left;margin-right:$margin-normal;}
.changeAttributes.variant1 #btn_submit input{@include button;clear:left;float:left;}
.changeAttributes.variant1 input[type="checkbox"]+label {display:inline;}

.changeAttributes.variant1 .modifypersonalattributeinfo_input_container_text{
  float:right;
  input{
    width:240px;
  }
}