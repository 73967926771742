@charset "UTF-8";

.form.variant1 #FormbuilderV2RenderControl h2,
.form.variant1 #FormbuilderV2RenderControl h3,
.form.variant1 #FormbuilderV2RenderControl h4,
.form.variant1 #FormbuilderV2RenderControl h5 {margin:$margin-normal 0;}
.form.variant1 table {width:100%;border:0;background:none;}
.form.variant1 table td {line-height:rem-calc(30);padding-left:0;padding-right:0;}
.form.variant1 table tr {border-bottom:1px solid $border-color;}
.form.variant1 table tr.even,
.form.variant1 table tr.alt,
.form.variant1 table tr:nth-of-type(2n) {background:none;}
.form.variant1 table tr table tr {background:none;border:none;}
.form.variant1 table tr table tr td {background:none;border:none;}
.form.variant1 table td.formbuilder_label {font-size:rem-calc(16);width:40%;padding-left:0;padding-right:0;}
.form.variant1 table td.formbuilder_element table{border:0;margin:0;}
.form.variant1 table td.formbuilder_element table tr{border:0;}
.form.variant1 table td.formbuilder_element table td{padding-top:0;padding-bottom:0;}
.form.variant1 table input {width:100%;margin:0;color:$main-color;font-size:$main-font-size;}
.form.variant1 table input[type=checkbox] {margin:0 10px 0 0;}
.form.variant1 input[type=radio],
.form.variant1 input[type=checkbox] {width:auto;}
.form.variant1 table td textarea {margin-bottom:0;color:$main-color;font-size:$main-font-size;}
.form.variant1 .error_strings {color:$primary-color;}
// .form.variant1 .captcha_image{float:left;margin-bottom:$margin-normal;margin-right:$margin-normal;}
// .form.variant1 .captcha_button input {@include button;width:auto;}
// .form.variant1 .captcha_input{clear:left;}
.form.variant1 .formbuilder_form_submit_button input {@include button;}

.form.variant1 #captcha {margin:$margin-normal 0;}
.form.variant1 #captcha br {display:none;}
.form.variant1 #captcha > div:first-child,
.form.variant1 #captcha .captcha_image {float:left;}
.form.variant1 #captcha .captcha_button input {font-family:'FontAwesome';border:0;background:#ededed;height:50px;color:$black;border-radius:0;margin:0;padding:rem-calc(10);}
.form.variant1 #captcha #captcha_description_container {display:none;}
.form.variant1 #captcha .captcha_input input {clear:left;width:233px;}
.form.variant1 table.onderdelenOverzicht td.info_label{vertical-align:top;padding-right:$padding-normal;}