@charset "UTF-8";

// Default Variables

// Standard slider styling

.slider.variant3 {
    height: 100%;
	width: 100%;
	min-height: $slider-height;
	padding-top: 1px;
}

.slider.variant3 .slideWrapper { 
	margin: 0;
	height: $slider-height;
	overflow: hidden;
}

.slider.variant3 .slideWrapper .slide {
	position: relative;
	display: none;
	height: $slider-height;
	float: left;
	background-position: center right;
	cursor: pointer;
}


// Own slider styling
.slider.variant3 .slide {padding:$slider-padding;position:relative;@include slider-slide;}
.slider.variant3 .slide.active {@include slider-slide-active;}
.slider.variant3 .slide:not(.active) {@include slider-slide-inactive;}
.slider.variant3 .slide img{position:absolute;left:0;top:0;width:100%;}
.slider.variant3 .slide .caption {margin-left:$margin-large;@include slider-caption;}
.slider.variant3 .slide .caption a {@include slider-caption-link;}
.fullWidth .slider.variant3 .slide .caption {margin-left:0;}
.slider.variant3 .slide .caption h3,
.slider.variant3 .slide .caption h3 a {@include slider-caption-title;}
.slider.variant3 .slide .caption h3:empty {display:none;}
.fullWidth .slider.variant3 .slide .caption:empty {background:none;padding:0;}
.slider.variant3 .sliderImg{display:none;}
@media only screen and (max-width: 40em) {
	.slideWrapper {width:100%!important;}
    .slider.variant3 .slide.active {background-size:cover!important;width:100%!important;/*display:block!important;*/}
}