@charset "UTF-8";

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($logoBar-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
    @else {
        @return url($logoBar-font-path + $url);
    }
}

/* Icons */
@if $logoBar-font-family == "slick" {
    @font-face {
        font-family: "slick";
        src: slick-font-url("slick.eot");
        src:    slick-font-url("slick.eot?#iefix") format("embedded-opentype"),
                slick-font-url("slick.woff") format("woff"),
                slick-font-url("slick.ttf") format("truetype"),
                slick-font-url("slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}

/* Arrows */

.logoBar.variant1 .slick-prev,
.logoBar.variant1 .slick-next {
    @include logoBar-arrows;
}

.logoBar.variant1 .slick-prev {
    @include logoBar-prev;
}

.logoBar.variant1 .slick-next {
    @include logoBar-next;
}

/* Dots */
.logoBar.variant1 .slick-slider {
    @include logoBar;
}

.logoBar.variant1 .slick-dots {
    @include logoBar-dots;
}
.logoBar.variant1 .slick-slide{
    @include logoBar-slide;
}
.logoBar.variant1 .slick-slide img{
    @include logoBar-slide-img;
}
.logoBar.variant1 .slick-center{
    @include logoBar-center-slide;
}
